import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Tracking } from '@freelancer/tracking';

@Component({
  selector: 'webapp-compat-tracking',
  template: ` <fl-tracking></fl-tracking> `,
})
export class CompatTrackingComponent implements OnInit {
  constructor(private tracking: Tracking) {}

  ngOnInit(): void {
    const w = window as any;
    w.webappCompatTracking = {
      trackCustomEvent: (
        name: string,
        section: string,
        extraParams?: { [key: string]: string | number | string[] },
      ) => this.tracking.trackCustomEvent(name, section, extraParams),
    };

    // This callback function is defined at modules/@shared/ads-tracking-wrapper/index.js
    if (w.webappCompatTrackingReadyCallback) {
      w.webappCompatTrackingReadyCallback();
    }
  }
}
